import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useContext,
  PropsWithChildren,
} from 'react';
import ModalContainer from '@components/AuthForm/ModalContainer';

type LoginFormContextType = {
  showSignUp: () => void;
  showLogin: () => void;
};

const LoginFormContext = createContext<LoginFormContextType>({
  showSignUp: () => {},
  showLogin: () => {},
});

export function Provider({ children }: PropsWithChildren) {
  const [showModal, setShowModal] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);

  const signUp = useCallback(() => {
    setIsSignUp(true);
    setShowModal(true);
  }, [setIsSignUp, setShowModal]);

  const login = useCallback(() => {
    setIsSignUp(false);
    setShowModal(true);
  }, [setIsSignUp, setShowModal]);

  const contextValue = useMemo(
    () => ({
      showSignUp: signUp,
      showLogin: login,
    }),
    [signUp, login],
  );

  return (
    <LoginFormContext.Provider value={contextValue}>
      {showModal && (
        <>
          <div className="bg-rp-black-faded fixed h-full left-0 overflow-auto top-0 w-full z-500" />
          <div className="-translate-x-1/2 -translate-y-1/2 fixed flex flex-col items-center justify-between left-1/2 rounded-xl top-1/2 transform-gpu w-full z-500 h-full overflow-y-auto d:h-auto">
            <ModalContainer
              setIsLoginButtonClicked={setShowModal}
              isSignUpButtonClicked={isSignUp}
            />
          </div>
        </>
      )}
      {children}
    </LoginFormContext.Provider>
  );
}

export default function useAuthForm() {
  return useContext(LoginFormContext);
}
