import { store } from '@context/store';
import HotelApi from '@customTypes/hotel-api';
import { HotelApiResponseItem } from '@customTypes/hotel-api-response';
import { getHotelsV5 } from '@utils/services';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';

export default function useHomePageHotels() {
  const globalState = useContext(store);
  const { state } = globalState;
  const { userLocation } = state;
  const router = useRouter();

  const [homePageHotels, setHomePageHotels] = useState<HotelApi[]>([]);
  const [selectedCity, setSelectedCity] = useState<string>();
  const [selectedCityId, setSelectedCityId] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFetchComplete, setIsFetchComplete] = useState<boolean>(false);

  const removeDuplicates = (allHotels: HotelApi[]) => {
    const duplicatesRemovedHotels: HotelApi[] = [];
    if (allHotels.length > 0) {
      const hotelIds: number[] = [];
      allHotels.forEach((hotel: HotelApi) => {
        if (hotelIds.indexOf(hotel.id) === -1) {
          duplicatesRemovedHotels.push(hotel);
          hotelIds.push(hotel.id);
        }
      });
    }
    return duplicatesRemovedHotels;
  };

  useEffect(() => {
    const getHotels = async () => {
      try {
        setIsFetchComplete(false);
        setIsLoading(true);

        if (userLocation) {
          const cityId = userLocation.id;
          if (cityId) {
            setSelectedCity(userLocation.name);
            setSelectedCityId(cityId);
            const query = `city_id:${cityId}&stage=${1}&page=${0}`;
            const hotelData = await getHotelsV5(query, {});

            if (hotelData && hotelData instanceof Array) {
              const stageOneHotels = (hotelData as HotelApiResponseItem[])[0].hotels.filter(
                (hotel) => hotel.availability && !hotel.closedForSeason,
              );

              if (stageOneHotels.length < 6) {
                const stageTwoHotels = (hotelData as HotelApiResponseItem[])[1].hotels.filter(
                  (hotel) => hotel.availability && !hotel.closedForSeason,
                );
                const stageOneAndTwoHotels = [...stageOneHotels, ...stageTwoHotels];

                if (stageOneAndTwoHotels.length < 6) {
                  const stageThreeHotels = (hotelData as HotelApiResponseItem[])[2].hotels.filter(
                    (hotel) => hotel.availability && !hotel.closedForSeason,
                  );

                  setHomePageHotels(
                    removeDuplicates([
                      ...stageOneHotels,
                      ...stageTwoHotels,
                      ...stageThreeHotels,
                    ]).slice(0, 6),
                  );
                  return;
                }

                setHomePageHotels(removeDuplicates(stageOneAndTwoHotels).slice(0, 6));
                return;
              }

              setHomePageHotels(removeDuplicates(stageOneHotels).slice(0, 6));
            }
          }
        }
      } catch (error) {
        //
      } finally {
        setIsFetchComplete(true);
        setIsLoading(false);
      }
    };
    if (userLocation && (router.pathname === '/' || router.pathname === '/mastercard')) getHotels();
  }, [router.pathname, userLocation]);

  return { homePageHotels, isFetchComplete, isLoading, selectedCity, selectedCityId };
}
